export const COLOR_PRIMARY = '#512de0';
export const COLOR_PRIMARY_HOVER = '#765be3';
export const COLOR_SECONDARY = '#9583de';
export const COLOR_SECONDARY_HOVER = '#b2a6e0';
export const COLOR_ALTERNATIVE = '#8520c9';
export const COLOR_ALTERNATIVE_HOVER = '#9a5ac4';
export const COLOR_TEXT = 'rgba(255, 255, 255, 0.9)';
export const COLOR_TEXT_SECONDARY = 'rgba(255, 255, 255, 0.7)';
// export const COLOR_BACKGROUND = 'rgba(231, 198, 255, 0.2)';
export const COLOR_BACKGROUND = 'rgba(66, 74, 92, 0.4)';

export const COLOR_BACKGROUND_HOVER = 'rgba(231, 198, 255, 0.4)';
export const COLOR_BACKGROUND_SOLID = 'rgba(26, 28, 33, 1)';
export const COLOR_BACKGROUND_SOLID_HOVER = '#4b5669';
export const COLOR_BACKGROUND_LIGHT = 'rgba(231, 198, 255, 0.1)';
