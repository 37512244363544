export const PATH_HOME = '/';
export const PATH_ABOUT = '/ueber-uns';
export const PATH_TOOLS = '/tools';
export const PATH_TUTORIALS = '/tutorials';
export const PATH_EVENTS = '/events';
export const PATH_BLOG = '/blog';
export const PATH_BLOG_ARTICLE = '/blog/:slug';
export const PATH_CONTACT = '/kontakt';
export const PATH_FESTIVALS = '/festivals';
export const PATH_FILMS = '/filme';
export const PATH_MEDIAPROJECTS = '/medienprojekte';
